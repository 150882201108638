
import React, {useEffect} from "react";
import { ToastContainer } from "react-toastify";
import { Suspense } from "react";
import "./App.css";
import "./assets/css/style.css";
import "./assets/js/custom";
import "react-toastify/dist/ReactToastify.css";
import BeatLoader from "react-spinners/BeatLoader";
import Router from "./routes/Router";
import { loadpaymentsettings, loadsettings } from "./store/reducers/settings";
import { useSelector } from "react-redux";

function App() {

  useEffect(() => {
    loadsettings("all");
    loadpaymentsettings("payment_method")
  }, []);

  const loading = useSelector((state) =>  state.settings.loading)


  return (
    <div className="App">

      <ToastContainer />
      <Suspense
        fallback={
          <div className="loader">
            <BeatLoader className="inner_loader" />
          </div>
        }
      >
        {loading ?  <Router /> : null}

      </Suspense>

    </div>
  );
}

export default App;
